import { mediaServerError, selectAppReconnecting } from 'features/application/applicationSlice';
import {
  publishingConnectionEstablished,
  streamingConnectionEstablished,
} from 'features/streaming/actions';
import Janus, { JanusJS } from 'lib/janus';
import { SignalingSocket } from 'services/signaling';
import { store } from 'store/store';
import { logger } from 'utils/logger';
import { NO_REPLICATED_CONNECTION } from 'utils/webrtc/errors';
import {
  JanusConnection,
  JanusConnectionOptions,
  JanusConnectionStatus,
  JanusConnectionType,
  PublishingFeedStatus,
  RTCClient,
  ServerHandle,
} from 'utils/webrtc/index';

export class MediaServerConnector {
  supressErrors: boolean = false;

  connections: Record<string, JanusConnection> = {};

  pendingServerConnections: JanusConnection[] = [];

  subscriptionQueue: any[] = [];

  // eslint-disable-next-line
  constructor() {}

  connectToSubscriptionServer = (
    handle: ServerHandle,
    options: JanusConnectionOptions,
    replicatedHandle?: string
  ) => {
    if (this.connections[handle]) {
      return;
    }

    let janus: JanusJS.Janus;
    let status = JanusConnectionStatus.connecting;

    if (replicatedHandle) {
      janus = this.replicateJanusConnection(handle, replicatedHandle);
      status = JanusConnectionStatus.connected;
    } else {
      janus = this.createJanusConnection(handle, options);
    }

    this.connections[handle] = {
      status,
      connected: status === JanusConnectionStatus.connected,
      replicatedHandle,
      janus,
      handle,
      type: JanusConnectionType.subscription,
      options,
    };

    if (RTCClient.publishingFeed.status === PublishingFeedStatus.connected) {
      // @TODO: This may require some attention in future
      // when we have dynamic subscription servers for scaling
      RTCClient.subscribeToServer(this.connections[handle]);
    } else {
      this.pendingServerConnections.push(this.connections[handle]);
    }
  };

  connectToPublishingServer = (
    handle: ServerHandle,
    options: JanusConnectionOptions,
    replicatedHandle?: string
  ) => {
    if (!this.connections[handle]) {
      let janus: JanusJS.Janus;
      let status = JanusConnectionStatus.connecting;

      if (replicatedHandle) {
        janus = this.replicateJanusConnection(handle, replicatedHandle);
        status = JanusConnectionStatus.connected;
      } else {
        janus = this.createJanusConnection(handle, options);
      }

      this.connections[handle] = {
        status,
        connected: status === JanusConnectionStatus.connected,
        replicatedHandle,
        janus,
        handle,
        type: JanusConnectionType.publishing,
        options,
      };
    }

    if (this.connections[handle].status === JanusConnectionStatus.connected) {
      store.dispatch(publishingConnectionEstablished());
    }
  };

  private replicateJanusConnection = (targetHandle: ServerHandle, sourceHandle: ServerHandle) => {
    if (this.connections[sourceHandle]?.janus) {
      return this.connections[sourceHandle]?.janus;
    }

    throw NO_REPLICATED_CONNECTION(sourceHandle);
  };

  private createJanusConnection = (handle: ServerHandle, options: JanusConnectionOptions) =>
    new Janus({
      server: options.mediaServer.url,
      token: options.mediaServer.token,
      destroyOnUnload: true,
      iceServers: options.iceServers,
      success: () => {
        logger
          .remote()
          .info(`Janus connection with handle=${handle} to ${options.mediaServer.url} established`);

        this.connections[handle].status = JanusConnectionStatus.connected;

        if (this.connectionsReady()) {
          logger.log('All streaming connections are ready');

          RTCClient.isMediaServerError = false;
          this.enableSubscription();

          store.dispatch(streamingConnectionEstablished());
        }
      },
      error: (error: string) => {
        if (RTCClient.isUnloading || RTCClient.supressErrors) {
          // mute errors when session is destroyed/tab is closed;
          return;
        }

        logger
          .remote({ tier: 1 })
          .log('An error occurred on the media server, trying to reconnect...');
        logger
          .remote({ system: true, capture: 'streaming' })
          .info(`An error occurred on the media server:`, error);

        RTCClient.isMediaServerError = true;
        store.dispatch(mediaServerError());

        SignalingSocket.close(undefined, 'janus_error');
      },
      destroyed: () => {
        // TODO: USE SOMETHING INSTEAD 'options' field;
        if (this.connections[handle].options.mediaServer.token === options.mediaServer.token) {
          this.cleanupConnection(handle);
        }
      },
    });

  cleanupConnection = async (handle: string) => {
    const connection = this.connections[handle];

    if (!connection) {
      return;
    }

    if (connection.type === JanusConnectionType.publishing) {
      const isReconnecting = selectAppReconnecting(store.getState());

      RTCClient.publishingFeed.cleanupConnection();

      if (!isReconnecting) {
        await RTCClient.screensharingFeed.stopScreenshare();
        RTCClient.screensharingFeed.cleanupConnection();
      }
    }

    if (connection.type === JanusConnectionType.subscription) {
      RTCClient.receivingFeed.cleanupConnection();
    }

    const connections = { ...this.connections };

    delete this.connections[handle];

    // cleanup replicated connections if any
    Object.values(connections).forEach((c) => {
      if (c.replicatedHandle === handle) {
        this.cleanupConnection(c.handle);
      }
    });
  };

  connectionsReady = () =>
    Object.values(this.connections).every(
      (connection) => connection.status === JanusConnectionStatus.connected
    );

  enableSubscription = () => {
    this.pendingServerConnections.forEach((connection) => {
      RTCClient.subscribeToServer(connection);
    });

    this.pendingServerConnections = [];
    if (this.subscriptionQueue.length) {
      RTCClient.subscribeConnections(this.subscriptionQueue);
    }

    RTCClient.subscribeToRoomUsers();
  };

  queueStreamSubscription = (streams: any[]) => {
    this.subscriptionQueue = [...this.subscriptionQueue, ...streams];
  };
}
