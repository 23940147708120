import {
  signalingTranscriptionStarted,
  signalingTranscriptionStopped,
  speechCaptioned,
  startTranscriptionRequested,
  stopTranscriptionRequested,
} from 'features/transcripts/actions';
import {
  captionsToggled,
  fontSizeChanged,
  spokenLanguageChanged,
} from 'features/transcripts/slices/captions/captionsSlice';
import { onCaptionsToggledSaga } from 'features/transcripts/sagas/onCaptionsToggledSaga';
import { onFontSizeChangedSaga } from 'features/transcripts/sagas/onFontSizeChangedSaga';
import { onSpeechCaptionedSaga } from 'features/transcripts/sagas/onSpeechCaptionedSaga';
import { onSpokenLanguageChangedSaga } from 'features/transcripts/sagas/onSpokenLanguageChangedSaga';
import { takeEvery } from 'redux-saga/effects';
import { startTranscriptionSaga } from 'features/transcripts/sagas/startTranscriptionSaga';
import { stopTranscriptionSaga } from 'features/transcripts/sagas/stopTranscriptionSaga';
import { disableCaptionsSaga } from 'features/transcripts/sagas/disableCaptionsSaga';
import { enableCaptionsSaga } from 'features/transcripts/sagas/enableCaptionsSaga';

export default function* captionsWatcher() {
  yield takeEvery(startTranscriptionRequested, startTranscriptionSaga);
  yield takeEvery(stopTranscriptionRequested, stopTranscriptionSaga);

  yield takeEvery(signalingTranscriptionStarted, enableCaptionsSaga);
  yield takeEvery(signalingTranscriptionStopped, disableCaptionsSaga);

  yield takeEvery(speechCaptioned, onSpeechCaptionedSaga);
  yield takeEvery(captionsToggled, onCaptionsToggledSaga);
  yield takeEvery(spokenLanguageChanged, onSpokenLanguageChangedSaga);
  yield takeEvery(fontSizeChanged, onFontSizeChangedSaga);
}
