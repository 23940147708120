import { styled } from '@mui/material/styles';
import { selectDeviceType } from 'features/application/applicationSlice';
import { CaptionsSettingsControl } from 'features/transcripts/slices/captions/CaptionsSettingsControl';
import {
  selectCaptionsDisplayed,
  selectCaptionsFontSize,
} from 'features/transcripts/slices/captions/captionsSlice';
import { RecorderGuard } from 'features/recorder/RecorderGuard';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { Box } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { CaptionsText } from 'features/transcripts/slices/captions/CaptionsText';

const InnerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'smallLayout',
})<{ smallLayout: boolean }>(({ theme, smallLayout }) => ({
  zIndex: theme.zIndex.appBar,
  position: 'absolute',
  top: 0,
  left: 0,
  maxWidth: smallLayout ? '360px' : '680px',
  width: 'calc(100% - 50px)',
  borderRadius: 8,
  padding: theme.spacing(2, 4),
  cursor: 'pointer',
  color: theme.palette.common.white,
  background: 'rgba(0, 0, 0, 0.7)',
}));

export const CaptionsContainer = ({
  roomWidth,
  roomHeight,
}: {
  roomWidth: number;
  roomHeight: number;
}) => {
  const { isMobile, isPortrait } = useBreakpoints();
  const deviceType = useAppSelector(selectDeviceType);
  const smallLayout = isMobile || deviceType === 'mobile';

  const captionsEnabled = useAppSelector(selectCaptionsDisplayed);

  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const nodeRef = useRef<HTMLDivElement | null>(null);
  const coordsRef = useRef<{ x: number; y: number; ready: boolean } | null>(null);

  const fontSize = useAppSelector(selectCaptionsFontSize);

  // const { roomWidth, roomHeight } = useContext(RoomLayoutValuesContext);

  const updateCoords = (x: number, y: number) => {
    setCoords({ x, y });
    coordsRef.current = { x, y, ready: true };
  };

  const captionsReady = captionsEnabled;

  useEffect(() => {
    if (!captionsReady || !nodeRef.current) {
      return;
    }

    if (coordsRef.current?.ready) {
      coordsRef.current.ready = false;
    }
  }, [captionsReady, isPortrait]);

  useEffect(() => {
    if (!captionsReady || !nodeRef.current) {
      return;
    }

    const realRoomWidth =
      // eslint-disable-next-line no-nested-ternary
      deviceType === 'mobile'
        ? isPortrait
          ? Math.min(roomWidth, roomHeight)
          : Math.max(roomWidth, roomHeight)
        : roomWidth;

    const realRoomHeight =
      // eslint-disable-next-line no-nested-ternary
      deviceType === 'mobile'
        ? isPortrait
          ? Math.max(roomWidth, roomHeight)
          : Math.min(roomWidth, roomHeight)
        : roomHeight;

    const containerWidth = nodeRef.current.offsetWidth;
    const containerHeight = nodeRef.current.offsetHeight;

    if (!coordsRef.current || !coordsRef.current?.ready) {
      const initialX = (realRoomWidth - containerWidth) / 2;

      let initialY = 0;

      if (containerHeight > realRoomHeight) {
        // whoops;
        // figure this case lanter
      } else {
        initialY = realRoomHeight - containerHeight;
      }

      updateCoords(initialX, initialY);

      return;
    }

    let modX = coordsRef.current.x;
    let modY = coordsRef.current.y;

    if (coordsRef.current.x + containerWidth > realRoomWidth) {
      modX = realRoomWidth - containerWidth - 10;
    } else if (coordsRef.current.x < 10) {
      modX = 10;
    }

    if (coordsRef.current.y + containerHeight > realRoomHeight) {
      modY = realRoomHeight - containerHeight - 10;
    }

    if (modX !== coordsRef.current.x || modY !== coordsRef.current.y) {
      updateCoords(modX, modY);
    }
  }, [roomWidth, roomHeight, captionsReady, isPortrait, deviceType]);

  if (!captionsReady) {
    return null;
  }

  return (
    <Draggable
      onDrag={(_, { x, y }) => updateCoords(x, y)}
      onStop={(_, { x, y }) => updateCoords(x, y)}
      position={coords}
      bounds="parent"
      nodeRef={nodeRef}
      cancel=".captions-settings-control"
    >
      <InnerContainer sx={{ fontSize }} ref={nodeRef} smallLayout={smallLayout}>
        <RecorderGuard>
          <Box
            className="captions-settings-control"
            sx={{ position: 'absolute', right: '.5rem', top: '.5rem', zIndex: 1, fontSize: 16 }}
          >
            <CaptionsSettingsControl />
          </Box>
        </RecorderGuard>
        <CaptionsText />
      </InnerContainer>
    </Draggable>
  );
};
