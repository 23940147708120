import { captionsToggled } from 'features/transcripts/slices/captions/captionsSlice';
import {
  layoutModeChanged,
  selectCurrentRecordingMode,
  selectShowCaptionsInRecordings,
} from 'features/layout/features/config/configSlice';
import { contentWidthChanged } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { LayoutMode } from 'features/layout/types';
import { call, put, select } from 'redux-saga/effects';
import { startRecorder } from 'features/recorder/utils';
import { SignalingSocket } from 'services/signaling';
import { selectRoomToken } from 'features/room/roomSlice';
import { RoomToken } from 'features/room/types';
import * as Sentry from '@sentry/react';
import { layoutConfig } from 'utils/layout';
import { logger } from 'utils/logger';

export function* startRecorderSaga() {
  try {
    const token: RoomToken = yield select(selectRoomToken);
    if (!token.parsedData) {
      throw new Error('Missing token data');
    }

    yield call(startRecorder, {
      recordingId: token.parsedData.recordingId!,
      chunkIntervalMinutes: token.parsedData.chunkIntervalMinutes,
    });

    const targetMode: LayoutMode = yield select(selectCurrentRecordingMode);

    yield put(layoutModeChanged(targetMode));

    yield put(contentWidthChanged(layoutConfig.maxContentWidth));

    const showCaptions: boolean = yield select(selectShowCaptionsInRecordings);

    if (showCaptions) {
      yield put(captionsToggled());
    }

    yield call(SignalingSocket.send, { event: 'recordingStarted' });
  } catch (error) {
    yield call(SignalingSocket.send, { event: 'recordingFailure' });

    Sentry.captureException(error);
    logger.remote({ tier: 1 }).error('Recording start failed', error);
  }
}
